module.exports = {
  "name": "all4pack",
  "client": "Mobile spot",
  "description": "Mobile app for test",
  "validLangs": ["fr", "en"],
  "version": {
    "mainVersion": "2.0.0",
    "buildVersion": {
      "iOS": "32",
      "android": "31",
      "web": "31"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "12.2",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 31,
    "androidSigning": {
      "keyAlias": "mobilespot",
      "keyStorePassword": "jee1Uu0Hieloh7ba"
    },
    "app": "app-react"
  },
  "undeliveredFolders": ["source", "exports"],
  "crypto": false,
  "web": {}
};