module.exports = {
  DEFAULT_PROFILE: 'default',
  WEBAPP_EXH_PROFILE: 'webapp-exh',
  WEBAPP_EVT_PROFILE: 'webapp-evt'
  /*
  WEBAPP_SPK_PROFILE: 'webapp-spk',
  VISITOR_PROFILE: 'visitor',
  EXHIBITOR_PROFILE: 'exhibitor',
  WEBAPP_MAP: 'webapp-map',*/

};